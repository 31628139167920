import Box from "@mui/material/Box";

import LogoIcon from "components/Icons/Logo";
import SocialGroup from "components/SocialGroup";

import { SOCIALS } from "enums/socials";

import { EXTERNAL_ROUTES } from "config/routes";

const UNIQLY_SOCIALS = [
  { name: SOCIALS.TWITTER, link: EXTERNAL_ROUTES.UNIQLY_TWITTER },
  { name: SOCIALS.DISCORD, link: EXTERNAL_ROUTES.UNIQLY_DISCORD },
  { name: SOCIALS.TELEGRAM, link: EXTERNAL_ROUTES.UNIQLY_TELEGRAM },
  { name: SOCIALS.INSTAGRAM, link: EXTERNAL_ROUTES.UNIQLY_INSTAGRAM },
  { name: SOCIALS.FACEBOOK, link: EXTERNAL_ROUTES.UNIQLY_FACEBOOK },
  { name: SOCIALS.LINKEDIN, link: EXTERNAL_ROUTES.UNIQLY_LINKEDIN },
  { name: SOCIALS.MEDIUM, link: EXTERNAL_ROUTES.UNIQLY_MEDIUM },
];

const FooterSocials = () => {
  return (
    <>
      <Box
        sx={{
          mb: { xs: 4, md: 3 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LogoIcon sx={{ width: 165, height: 56 }} />
      </Box>
      <SocialGroup items={UNIQLY_SOCIALS} />
    </>
  );
};

export default FooterSocials;
