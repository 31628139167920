import { FC } from "react";

import Grid, { GridProps } from "@mui/material/Grid";

const GridContainer: FC<GridProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Grid container spacing={2} {...rest}>
      {children}
    </Grid>
  );
};

export default GridContainer;
