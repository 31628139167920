import isNil from "lodash/isNil";

export const formatCryptoPrice = (
  num: number | undefined | null,
): string | null => {
  if (isNil(num)) return null;

  return num.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
};

export const formatDollarsPrice = (num: number | undefined | null): string => {
  if (isNil(num)) return "";

  return Number(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};
