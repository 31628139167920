import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const socialLinkStyles = {
  social: (theme: Theme): SystemStyleObject<Theme> => ({
    marginRight: 3,
    [theme.breakpoints.down("md")]: {
      margin: 2,
    },
    "& svg": {
      fill: theme.palette.background.default,
      color: theme.palette.background.default,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderRadius: "50%",
      width: 26,
      height: 26,
      padding: 0.5,
    },
    "&:hover svg": {
      color: theme.palette.background.default,
    },
    "&:hover svg path": {
      fill: theme.palette.text.primary,
    },
  }),
};

export default socialLinkStyles;
