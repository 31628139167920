import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";

import { OrderBy, Pagination } from "interfaces/Params.interface";

const SUPPORTED_DIRECTIONS = ["asc", "desc"];
const MAX_LIMIT = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 25;

const getFilterName = (string: string): string => {
  return string.substring(string.lastIndexOf("[") + 1, string.lastIndexOf("]"));
};

export const getPagination = (page: number, limit: number): Pagination => {
  const limitToUse =
    limit && Number.isInteger(Number(limit)) ? limit : DEFAULT_LIMIT;

  return {
    page:
      page && Number.isInteger(Number(page)) && page > 0 ? page : DEFAULT_PAGE,
    limit: limit > MAX_LIMIT ? MAX_LIMIT : limitToUse,
  };
};

export const parseQuery = <O>(
  query: Record<string, string>,
): { filters: Record<string, string[]>; orderBy: OrderBy<O> | undefined } => {
  const filters = {};
  let orderBy;
  Object.entries(query).forEach(([name, value]) => {
    if (-1 !== name.indexOf("filters")) {
      if (getFilterName(name) === "id") {
        filters[getFilterName(name)] = value.split(",").map((i) => parseInt(i));
      } else if (isBoolean(value)) {
        filters[getFilterName(name)] = "true" === value;
      } else {
        filters[getFilterName(name)] = value
          .split(",")
          .filter((item) => !!item);
      }
    }

    if (-1 !== name.indexOf("orderBy")) {
      orderBy = {
        columnName: getFilterName(name),
        direction: SUPPORTED_DIRECTIONS.includes(value.toLowerCase())
          ? value
          : SUPPORTED_DIRECTIONS[0],
      };
    }
  });

  return {
    filters,
    orderBy,
  };
};

export const mapURLSearchParams = (
  queryParams: Record<string, string | number | boolean>,
): string => {
  const params = new URLSearchParams();
  for (const [name, value] of Object.entries(queryParams)) {
    params.set(
      name,
      isNumber(value) || isBoolean(value) ? String(value) : value,
    );
  }

  return params.toString();
};

export const slugify = (text: string): string => {
  return text
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
};

export const removeSpecialChars = (text: string): string => {
  return text.replace(/[^a-zA-Z0-9-]/g, "");
};
