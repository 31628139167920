import { FC } from "react";

import Grid, { GridProps } from "@mui/material/Grid";

const GridItem: FC<GridProps> = (props) => {
  const { children, sx = [], ...rest } = props;

  return (
    <Grid item xs={12} {...rest} sx={sx}>
      {children}
    </Grid>
  );
};

export default GridItem;
