import { FC } from "react";

import CustomLink, { CustomLinkProps } from "components/CustomLink";

import styles from "./styles";

const SocialLink: FC<CustomLinkProps> = ({ children, ...props }) => {
  return (
    <CustomLink sx={styles.social} target="_blank" rel="noreferrer" {...props}>
      {children}
    </CustomLink>
  );
};

export default SocialLink;
