import { useCallback, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import sumBy from "lodash/sumBy";
import isEqual from "lodash/isEqual";

import {
  CartApiMintItem,
  CartApiRedeemItem,
  CartMintItem,
  CartRedeemItem,
} from "modules/Cart/ShoppingCart.interface";

import { selectChainId, selectAddress } from "redux/slices/metamask";
import {
  addItem,
  removeItems,
  decreaseAmountById,
  selectCartItems,
  selectCartModalOpen,
  toggleModal,
  clearCart as removeAll,
} from "redux/slices/shoppingCart";
import { useAppDispatch, useAppSelector } from "redux/store";

import { PAYMENT_FIATS } from "enums/paymentTokens";
import { CART_ITEM_TYPES } from "enums/cartItemTypes";
import { CHAIN_IDS } from "enums/chainIds";

import { API_ROUTES } from "config/routes";

import { mapURLSearchParams } from "utils/query";
import { formatDollarsPrice } from "utils/priceFormatters";

import fetcher from "lib/fetchJson";

const useCart = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const items = useAppSelector(selectCartItems);
  const isCartModalOpen = useAppSelector(selectCartModalOpen);
  const chainId = useAppSelector(selectChainId);
  const metamaskAddress = useSelector(selectAddress);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["cart"],
    queryFn: async () => {
      try {
        const response = await fetcher<{
          data: {
            items: (CartApiMintItem | CartApiRedeemItem)[];
            canMint: boolean;
            canRedeem: boolean;
            hasMixedRefTokens: boolean;
            errors: string[];
            allowedChainIds: CHAIN_IDS[];
            areFiatsAvailable: boolean;
            fiatCurrency: PAYMENT_FIATS;
          };
        }>(
          `${API_ROUTES.CART}?${mapURLSearchParams({ chainId })}`,
          items,
          "POST",
        );

        return response.data;
      } catch {
        return {
          items: [],
          canMint: false,
          canRedeem: false,
          hasMixedRefTokens: false,
          errors: [],
          allowedChainIds: [],
          areFiatsAvailable: true,
          fiatCurrency: PAYMENT_FIATS.USD,
        };
      }
    },
    enabled: false,
  });

  useEffect(() => {
    refetch();
    // Refetch items when metamaskAddress changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metamaskAddress]);

  const cartItems: (CartMintItem | CartRedeemItem)[] = useMemo(() => {
    return (
      data?.items
        ?.map((apiItem) => {
          if (apiItem.type === CART_ITEM_TYPES.MINT) {
            const cartItem = items?.find(
              (item) =>
                apiItem.type === CART_ITEM_TYPES.MINT &&
                isEqual(apiItem.id, item.bundleId),
            );

            if (!cartItem) return null;

            return {
              ...apiItem,
              cartId: cartItem.cartId,
              amount: cartItem.amount ?? 1,
            };
          }
          if (apiItem.type === CART_ITEM_TYPES.REDEEM) {
            const cartItem = items?.find(
              (item) =>
                apiItem.type === CART_ITEM_TYPES.REDEEM &&
                isEqual(apiItem.contractAddress, item.contractAddress) &&
                isEqual(apiItem.tokenId, item.tokenId),
            );

            if (!cartItem) return null;

            return {
              ...apiItem,
              cartId: cartItem.cartId,
              amount: cartItem.amount ?? 1,
            };
          }

          return null;
        })
        .filter(
          (item): item is CartMintItem | CartRedeemItem => !!item?.cartId,
        ) ?? []
    );
  }, [data, items]);

  const totalCost = useMemo(() => {
    const sum = sumBy(cartItems, (item) => {
      const amount =
        items.find((cartItem) => cartItem.bundleId === item.id)?.amount ?? 1;
      return item.refPrice * amount;
    });

    return formatDollarsPrice(sum || 0);
  }, [cartItems, items]);

  const numberOfProducts = useMemo(
    () =>
      items.reduce((acc, item) => {
        return acc + item.amount ?? 1;
      }, 0),
    [items],
  );

  const toggleCartModal = () => {
    dispatch(toggleModal());
  };

  const addMintItemToCart = (bundleId: number, showCartModal = true) => {
    dispatch(addItem({ amount: 1, bundleId, type: CART_ITEM_TYPES.MINT }));
    if (showCartModal) {
      toggleCartModal();
    }
  };

  const addOrderItemToCart = (contractAddress: string, tokenId: number) => {
    dispatch(
      addItem({
        amount: 1,
        contractAddress,
        tokenId,
        type: CART_ITEM_TYPES.REDEEM,
      }),
    );
    toggleCartModal();
  };

  const removeFromCart = async (ids: string[]) => {
    dispatch(removeItems(ids));
    await queryClient.invalidateQueries({
      queryKey: ["cart"],
    });
    await refetch();
  };

  const increaseAmount = (bundleId: number) => {
    addMintItemToCart(bundleId, false);
  };

  const decreaseAmount = (id: string) => {
    dispatch(decreaseAmountById(id));
  };

  const clearCart = useCallback(() => {
    dispatch(removeAll());
  }, [dispatch]);

  const isItemInTheCart = useCallback(
    ({
      type,
      bundleId,
      tokenId,
      contractAddress,
    }:
      | {
          type: CART_ITEM_TYPES.MINT;
          bundleId: number;
          contractAddress?: never;
          tokenId?: never;
        }
      | {
          type: CART_ITEM_TYPES.REDEEM;
          bundleId?: never;
          contractAddress: string;
          tokenId: string | number;
        }) => {
      if (type === CART_ITEM_TYPES.MINT) {
        return items.some(
          (item) => item.type === type && item.bundleId === bundleId,
        );
      } else {
        return items.some(
          (item) =>
            item.type === type &&
            item.contractAddress === contractAddress &&
            item.tokenId === tokenId,
        );
      }
    },
    [items],
  );

  return {
    addMintItemToCart,
    addOrderItemToCart,
    removeFromCart,
    increaseAmount,
    decreaseAmount,
    closeModal: toggleCartModal,
    isCartModalOpen,
    isItemInTheCart,
    canMint: data?.canMint ?? true,
    areFiatsAvailable: data?.areFiatsAvailable ?? true,
    canRedeem: data?.canRedeem ?? true,
    hasMixedRefTokens: data?.hasMixedRefTokens ?? true,
    fiatCurrency: data?.fiatCurrency ?? PAYMENT_FIATS.USD,
    allowedChainIds: data?.allowedChainIds ?? [],
    errors: data?.errors ?? [],
    cartItems,
    numberOfProducts,
    totalCost,
    items,
    isLoading,
    getItems: refetch,
    clearCart,
  };
};
export default useCart;
