import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const headerLinksStyles = {
  navbarElement: (theme: Theme): SystemStyleObject<Theme> => ({
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      mt: 2,
      mb: 1,
    },
    [theme.breakpoints.up("lg")]: {
      mx: 3,
    },
    [theme.breakpoints.up("xl")]: {
      mx: 4,
    },
  }),
  columnOnMobile: (theme: Theme): SystemStyleObject<Theme> => ({
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  divider: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundColor: theme.palette.text.primary,
  }),
  link: (): SystemStyleObject<Theme> => ({
    marginRight: 0,
  }),
  slice: (): SystemStyleObject<Theme> => ({
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "145px",
    whiteSpace: "nowrap",
  }),
  linkDropdown: (theme: Theme): SystemStyleObject<Theme> => ({
    margin: 0,
    padding: 0,
    color: theme.palette.text.primary,
    marginRight: 0.5,
    textTransform: "uppercase",
    "& div": {
      fontWeight: 400,
    },
    "&:hover": {
      "& b": {
        color: theme.palette.text.primary,
      },
    },
  }),
  hoverUnderline: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      "&:hover:after": {
        content: "''",
        width: "130%",
        height: "1px",
        position: "absolute",
        top: 36,
        left: "-15%",
        background: theme.palette.primary.main,
      },
    },
  }),
};

export default headerLinksStyles;
