import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import FooterSocials from "components/FooterSocials";
import Divider from "components/Divider";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomLink from "components/CustomLink";

import { useMarketplaceUrl } from "hooks/useMarketplaceUrl";

import { APP_ROUTES } from "config/routes";

import styles from "./styles";

interface FooterProps {
  isEmbedded?: boolean;
  customFooterSocials?: JSX.Element | JSX.Element[];
}

const Footer: FC<FooterProps> = ({
  isEmbedded = false,
  customFooterSocials,
}) => {
  const marketplaceUrl = useMarketplaceUrl();

  if (isEmbedded) {
    return (
      <Box sx={styles.embeddedWrapper}>
        <Container>
          <GridContainer>
            <GridItem>
              <Typography variant="body2">
                Powered by{" "}
                <CustomLink href={process.env.NEXT_PUBLIC_APP_URL}>
                  uniqly.io
                </CustomLink>
              </Typography>
            </GridItem>
          </GridContainer>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={styles.wrapper}>
      <Container>
        {customFooterSocials || <FooterSocials />}

        <GridContainer sx={{ mb: 4 }}>
          <Divider mt={5} mb={2}>
            <GridItem xs={6} md={4}>
              <Typography component="h5" variant="h5" mb={2}>
                CONTACT
              </Typography>
              <Box display="flex" flexDirection="column">
                <CustomLink
                  size="small"
                  noDecoration
                  href="mailto: contact@uniqly.io"
                  sx={styles.link}
                >
                  contact@uniqly.io
                </CustomLink>
                <CustomLink
                  size="small"
                  noDecoration
                  href="mailto: marketing@uniqly.io"
                  sx={styles.link}
                >
                  marketing@uniqly.io
                </CustomLink>
              </Box>
            </GridItem>

            <GridItem xs={6} md={4}>
              <Typography component="h5" variant="h5" mb={2}>
                INFORMATION
              </Typography>
              <Box display="flex" flexDirection="column">
                <CustomLink
                  size="small"
                  noDecoration
                  href={APP_ROUTES.TERMS}
                  sx={styles.link}
                >
                  Terms & Conditions
                </CustomLink>
                <CustomLink
                  size="small"
                  noDecoration
                  href={APP_ROUTES.PRIVACY}
                  sx={styles.link}
                >
                  Privacy Policy
                </CustomLink>
              </Box>
            </GridItem>

            <GridItem xs={12} md={4}>
              <Typography component="h5" variant="h5" mb={2}>
                MENU
              </Typography>
              <Box display="flex" flexDirection="row">
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ width: "50%" }}
                >
                  <CustomLink
                    size="small"
                    noDecoration
                    href={APP_ROUTES.STORES}
                    sx={styles.link}
                  >
                    Stores
                  </CustomLink>
                  <CustomLink
                    size="small"
                    noDecoration
                    href={marketplaceUrl}
                    sx={styles.link}
                  >
                    Marketplace
                  </CustomLink>
                  <CustomLink
                    size="small"
                    noDecoration
                    href={APP_ROUTES.ABOUT}
                    sx={styles.link}
                  >
                    About
                  </CustomLink>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ width: "50%", ml: { xs: 2, md: 0 } }}
                >
                  <CustomLink
                    size="small"
                    noDecoration
                    href={APP_ROUTES.TEAM}
                    sx={styles.link}
                  >
                    Team
                  </CustomLink>
                  <CustomLink
                    size="small"
                    noDecoration
                    href={APP_ROUTES.FAQ}
                    sx={styles.link}
                  >
                    FAQ
                  </CustomLink>
                </Box>
              </Box>
            </GridItem>
          </Divider>
        </GridContainer>

        <GridContainer>
          <Divider>
            <GridItem>
              <Box
                display="flex"
                sx={{ justifyContent: { xs: "center", md: "left" } }}
              >
                <Typography variant="body2">
                  © 2022 uniqly.io. All Rights Reserved
                </Typography>
              </Box>
            </GridItem>
          </Divider>
        </GridContainer>
      </Container>
    </Box>
  );
};

export default Footer;
