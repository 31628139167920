import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { FC, useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import useCart from "modules/Cart/useCart";

import { selectIsUserFetched, selectUser } from "redux/slices/metamask";

import CustomLink from "components/CustomLink";
import SignInButton from "components/SignInButton";
import CustomButton from "components/CustomButton";
import ClientOnly from "components/ClientOnly";

import { APP_ROUTES } from "config/routes";
import {
  headerAboutEvent,
  headerMarketplaceEvent,
  headerStoresEvent,
  headerNfcTagsEvent,
} from "config/ga";

import { logGAEvent } from "utils/ga";

import styles from "./styles";

const CustomDropdown = dynamic(() => import("components/CustomDropdown"));
const HeaderDropdown = dynamic(() => import("components/HeaderDropdown"));

interface HeaderLinksProps {
  isEmbedded?: boolean;
}

const HeaderLinks: FC<HeaderLinksProps> = ({ isEmbedded = false }) => {
  const user = useSelector(selectUser);
  const isUserFetched = useSelector(selectIsUserFetched);
  const router = useRouter();
  const isOnItemPage =
    router.asPath.includes(APP_ROUTES.ITEMS) ||
    router.asPath.includes(APP_ROUTES.ITEMS_NFT) ||
    router.asPath.includes(APP_ROUTES.DISPENSER);
  const [isAboutDropdown, setAboutDropdown] = useState(false);

  const { numberOfProducts } = useCart();

  const handleGAEvent = (event) => {
    logGAEvent(event);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      width="100%"
      sx={styles.columnOnMobile}
      height="100%"
    >
      {!isEmbedded && !isOnItemPage && (
        <Box
          height="100%"
          display="flex"
          sx={[styles.columnOnMobile, { mr: { xs: 0, lg: 3 }, ml: { lg: 3 } }]}
        >
          <Box
            display="flex"
            alignItems="center"
            mx={2}
            sx={styles.navbarElement}
          >
            <Box sx={styles.hoverUnderline}>
              <CustomLink
                href={APP_ROUTES.NFC_TAGS}
                onClick={() => handleGAEvent(headerNfcTagsEvent)}
                noDecoration
                noDecorationOnHover
              >
                <Typography color="textPrimary" sx={{ whiteSpace: "nowrap" }}>
                  NFC TAGS
                </Typography>
              </CustomLink>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mx={2}
            sx={styles.navbarElement}
          >
            <Box sx={styles.hoverUnderline}>
              <CustomLink
                href={APP_ROUTES.STORES}
                onClick={() => handleGAEvent(headerStoresEvent)}
                noDecoration
                noDecorationOnHover
              >
                <Typography color="textPrimary">STORES</Typography>
              </CustomLink>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mx={2}
            sx={styles.navbarElement}
          >
            <Box sx={styles.hoverUnderline}>
              <CustomLink
                href={APP_ROUTES.MARKETPLACE}
                onClick={() => handleGAEvent(headerMarketplaceEvent)}
                noDecoration
                noDecorationOnHover
              >
                <Typography color="textPrimary">MARKETPLACE</Typography>
              </CustomLink>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mx={2}
            sx={styles.navbarElement}
          >
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <CustomDropdown
                onHoverAction
                caret={true}
                buttonProps={{
                  color: "transparent",
                  disableRipple: true,
                  sx: [styles.linkDropdown, styles.hoverUnderline],
                }}
                dropPlacement="bottom-start"
                extraMarginTop
                boxShadowStrong
                buttonText={
                  <CustomLink
                    href={APP_ROUTES.ABOUT}
                    sx={styles.link}
                    onClick={() => handleGAEvent(headerAboutEvent)}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">ABOUT</Typography>
                  </CustomLink>
                }
                dropdownList={[
                  <CustomLink
                    href={APP_ROUTES.ABOUT}
                    key={1}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">ABOUT</Typography>
                  </CustomLink>,
                  <CustomLink
                    href={APP_ROUTES.TEAM}
                    key={1}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">TEAM</Typography>
                  </CustomLink>,
                  <CustomLink
                    href={APP_ROUTES.CAREERS}
                    key={1}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">CAREERS</Typography>
                  </CustomLink>,
                  <CustomLink
                    href={APP_ROUTES.GUIDEBOOK}
                    key={1}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">GUIDEBOOK</Typography>
                  </CustomLink>,
                  <CustomLink
                    href={APP_ROUTES.FAQ}
                    key={1}
                    noDecoration
                    noDecorationOnHover
                  >
                    <Typography color="textPrimary">FAQ</Typography>
                  </CustomLink>,
                ]}
              />
            </Box>
            <Box
              sx={{ display: { xs: "flex", lg: "none" } }}
              flexDirection="column"
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={() => setAboutDropdown(!isAboutDropdown)}
              >
                <Typography color="textPrimary">ABOUT</Typography>
                {isAboutDropdown ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
              {isAboutDropdown && (
                <Box>
                  <Box ml={4} mt={4}>
                    <CustomLink
                      href={APP_ROUTES.ABOUT}
                      noDecoration
                      noDecorationOnHover
                    >
                      <Typography color="textPrimary">ABOUT</Typography>
                    </CustomLink>
                  </Box>
                  <Box ml={4} mt={4}>
                    <CustomLink
                      href={APP_ROUTES.TEAM}
                      noDecoration
                      noDecorationOnHover
                    >
                      <Typography color="textPrimary">TEAM</Typography>
                    </CustomLink>
                  </Box>
                  <Box ml={4} mt={4}>
                    <CustomLink
                      href={APP_ROUTES.CAREERS}
                      noDecoration
                      noDecorationOnHover
                    >
                      <Typography color="textPrimary">CAREERS</Typography>
                    </CustomLink>
                  </Box>
                  <Box ml={4} mt={4}>
                    <CustomLink
                      href={APP_ROUTES.GUIDEBOOK}
                      noDecoration
                      noDecorationOnHover
                    >
                      <Typography color="textPrimary">GUIDEBOOK</Typography>
                    </CustomLink>
                  </Box>
                  <Box ml={4} mt={4}>
                    <CustomLink
                      href={APP_ROUTES.FAQ}
                      noDecoration
                      noDecorationOnHover
                    >
                      <Typography color="textPrimary">FAQ</Typography>
                    </CustomLink>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        gap={1.5}
        sx={[
          {
            display: "flex",
            width: { xs: "100%", lg: "initial" },
            minWidth: 163,
            opacity: isUserFetched ? 1 : 0,
          },
          styles.columnOnMobile,
        ]}
      >
        {!isEmbedded && (
          <>
            <Box
              mx={2}
              sx={[
                { display: { xs: "flex", lg: "none" } },
                styles.navbarElement,
              ]}
            >
              <Box width="100px" height="1px" sx={styles.divider} />
            </Box>
          </>
        )}
        {user.isLoggedIn && (
          <CustomButton href={APP_ROUTES.MY_ITEMS} sx={{ height: 38 }}>
            My items
          </CustomButton>
        )}
        <Box sx={{ minWidth: 36 }}>
          {user.isLoggedIn ? (
            <HeaderDropdown isEmbedded={isEmbedded} />
          ) : (
            <SignInButton />
          )}
        </Box>
        {!isOnItemPage ? (
          <CustomButton href={APP_ROUTES.CART} sx={{ height: 38, width: 38 }}>
            <ClientOnly>
              {numberOfProducts > 0 ? (
                <Badge
                  badgeContent={numberOfProducts}
                  color="secondary"
                  sx={{ "& .MuiBadge-badge": { right: "-50%" } }}
                >
                  <ShoppingCartIcon />
                </Badge>
              ) : (
                <ShoppingCartIcon />
              )}
            </ClientOnly>
          </CustomButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default HeaderLinks;
