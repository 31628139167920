import { FC, ReactElement, useEffect, useState } from "react";

export type ClientOnlyProps = {
  children: ReactElement | null;
  placeholder?: ReactElement | null;
};

const ClientOnly: FC<ClientOnlyProps> = ({ children, placeholder = null }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted ? children : placeholder;
};

export default ClientOnly;
