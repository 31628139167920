export interface GaEvent {
  action: string;
  category: string;
  label: string;
}

// HEADER LINKS ACTIONS
export const headerMyItemsEvent: GaEvent = {
  action: "Click my items",
  category: "Header Links",
  label: "User moves to my items page",
};
export const headerMarketplaceEvent: GaEvent = {
  action: "Click marketplace",
  category: "Header Links",
  label: "User moves to marketplace page",
};
export const headerNfcTagsEvent: GaEvent = {
  action: "Click pin generator",
  category: "Header Links",
  label: "User moves to pin generator page",
};
export const headerStoresEvent: GaEvent = {
  action: "Click stores",
  category: "Header Links",
  label: "User moves to stores page",
};
export const headerRarityEvent: GaEvent = {
  action: "Click rarity",
  category: "Header Links",
  label: "User moves to rarity page",
};
export const headerAboutEvent: GaEvent = {
  action: "Click about",
  category: "Header Links",
  label: "User moves to about page",
};

// HEADER SOCIAL LINKS ACTIONS
export const headerTelegramEvent: GaEvent = {
  action: "Click Telegram",
  category: "Header Social Links",
  label: "User clicks Telegram social icon",
};
export const headerTwitterEvent: GaEvent = {
  action: "Click Twitter",
  category: "Header Social Links",
  label: "User clicks Twitter social icon",
};
export const headerDiscordEvent: GaEvent = {
  action: "Click Discord",
  category: "Header Social Links",
  label: "User clicks Discord social icon",
};

// CLAIMING ACTIONS
export const claimingEnterForm = {
  action: "Enter claiming form",
  category: "Claiming",
  label: "User enters claiming form",
};
