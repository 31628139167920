import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const footerStyles = {
  wrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    background: theme.palette.grey[900],
    paddingTop: 4,
    paddingBottom: 4,
  }),
  embeddedWrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    background: theme.palette.grey[900],
    paddingBottom: 1,
    minHeight: 32,
    marginTop: 6,
  }),
  link: (): SystemStyleObject<Theme> => ({
    margin: "4px 0",
  }),
  title: (): SystemStyleObject<Theme> => ({
    textTransform: "uppercase",
  }),
};

export default footerStyles;
