import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TiktokIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2652 5103 c-18 -9 -43 -30 -55 -47 l-22 -31 -5 -1730 -5 -1730 -23
-65 c-67 -184 -210 -320 -398 -376 -84 -25 -219 -24 -309 4 -244 74 -406 289
-408 542 -1 168 51 291 172 411 109 108 200 149 370 168 88 10 117 24 155 75
20 27 21 39 21 474 0 416 -1 449 -18 477 -40 64 -57 70 -212 69 -266 -3 -560
-92 -804 -246 -323 -202 -583 -531 -701 -887 -172 -518 -92 -1060 222 -1506
81 -115 278 -312 393 -393 610 -430 1418 -412 2000 43 285 223 496 541 589
886 54 202 56 240 56 1098 0 435 2 791 4 791 1 0 47 -21 102 -46 133 -62 303
-118 454 -148 143 -29 389 -52 444 -42 48 9 103 58 116 104 6 22 10 201 10
452 0 411 0 416 -23 460 -32 64 -65 79 -199 90 -139 12 -235 38 -353 95 -247
119 -427 330 -509 595 -16 50 -32 138 -40 213 -14 141 -26 168 -87 199 -31 16
-74 18 -469 18 -380 0 -439 -2 -468 -17z m728 -288 c0 -24 36 -175 57 -240
102 -317 344 -596 648 -748 91 -45 240 -94 335 -110 l75 -13 3 -253 2 -254
-62 7 c-262 30 -555 138 -773 286 -68 46 -88 54 -135 57 -70 5 -113 -20 -140
-80 -19 -40 -20 -77 -20 -977 0 -772 -3 -951 -15 -1025 -63 -388 -284 -729
-610 -940 -107 -70 -181 -107 -301 -148 -167 -58 -256 -72 -454 -71 -153 0
-189 4 -287 27 -548 128 -940 530 -1059 1087 -26 118 -26 391 -1 510 24 113
78 272 121 357 129 256 351 482 603 611 143 74 364 142 458 142 l25 0 -2 -251
-3 -251 -71 -18 c-309 -79 -551 -331 -630 -656 -25 -102 -22 -295 5 -399 72
-270 261 -488 515 -594 117 -49 196 -64 331 -64 130 0 211 15 324 60 221 88
406 275 492 497 61 157 59 95 59 1852 l0 1604 255 0 c140 0 255 -2 255 -5z"
      />
    </g>
  </SvgIcon>
);

export default TiktokIcon;
