import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const OpenseaIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.46117 5.3214H3.4055C3.4055 5.3214 4.47267 4.26057 3.45933 2.43339C3.4245 2.37006 3.3295 2.36689 3.2915 2.43022L1.46117 5.3214Z" />
    <path d="M3.68734 1.39149C3.68734 1.39149 5.54301 4.14015 3.68734 6.15732H4.80834V6.98698H3.68734C3.68734 6.98698 3.073 7.03131 3.073 6.42648V6.13515L0.906998 6.10348C0.906998 6.10348 1.04633 8.50698 2.85767 8.53864C4.669 8.57031 7.04084 8.56081 7.04084 8.56081C7.04084 8.56081 7.93701 8.66214 8.36768 7.42081C8.40885 7.30365 8.46585 7.19598 8.53868 7.09465C8.72235 6.83815 9.08651 6.41065 9.52985 6.29981V5.70765L7.28784 6.32198C7.28784 6.32198 6.72734 6.94581 6.52151 6.99965L5.58417 6.99015L5.59367 6.16998L6.25234 6.16048C6.25234 6.16048 9.22901 4.19715 5.57467 1.86016V1.10649C5.57467 1.10649 5.61901 0.783495 5.22001 0.770828C4.75134 0.758161 4.79884 1.12549 4.79884 1.12549L4.80834 1.71766C4.8115 1.71449 4.4125 1.46749 3.68734 1.39149Z" />
  </SvgIcon>
);

export default OpenseaIcon;
