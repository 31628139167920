import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

import { boxShadowBlack } from "theme";

const headerStyles = {
  header: (theme: Theme, { isFixed = true }): SystemStyleObject<Theme> => ({
    position: isFixed ? "fixed" : "absolute",
    left: 0,
    right: 0,
    width: "100%",
    zIndex: "7999",
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color", "border-color"]),
    borderBottom: "1px solid transparent",

    [theme.breakpoints.down("lg")]: {
      position: "absolute",
      marginBottom: -8,
    },
  }),
  active: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundColor: theme.palette.grey[900],
    borderColor: theme.palette.text.primary,
    ...boxShadowBlack,
  }),
  static: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "static",
    [theme.breakpoints.down("lg")]: {
      position: "static",
      marginBottom: -3,
    },
  }),
  cursor: (): SystemStyleObject<Theme> => ({
    cursor: "pointer",
  }),
  drawerModal: (): SystemStyleObject<Theme> => ({
    zIndex: "9999 !important",
  }),
  drawerPaper: (): SystemStyleObject<Theme> => ({
    minWidth: 250,
  }),
  closeIcon: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "absolute",
    right: 8,
    top: 8,
    zIndex: "1",
    color: theme.palette.text.primary,
  }),
};

export default headerStyles;
