import Image from "next/image";

import React, { FC, ReactNode, useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/icons-material/Menu";

import CustomLink from "components/CustomLink";
import HeaderLinks from "components/HeaderLinks";
import LogoIcon from "components/Icons/Logo";

import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from "utils/styles";

import styles from "./styles";

interface HeaderProps {
  isStatic?: boolean;
  isEmbedded?: boolean;
  headerDisclaimer?: ReactNode;
  isCustomClient?: boolean;
  logo?: string;
  isAlwaysActive?: boolean;
  headerBgColor?: string;
  headerBgImage?: string;
  headerMenuButtonColor?: string;
}

const Header: FC<HeaderProps> = ({
  isStatic = false,
  isEmbedded = false,
  headerDisclaimer = null,
  isCustomClient,
  logo,
  isAlwaysActive = false,
  headerBgColor,
  headerBgImage,
  headerMenuButtonColor,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isActive, setActive] = useState(isAlwaysActive);

  useEffect(() => {
    if (isAlwaysActive) return;
    if (typeof window !== "undefined" && !isEmbedded) {
      window.addEventListener("scroll", () =>
        setActive(window.pageYOffset > 10),
      );
    }
  }, [isAlwaysActive, isEmbedded]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box
      sx={[
        (theme) => styles.header(theme, { isFixed: !isEmbedded }),
        isActive && styles.active,
        Boolean(isStatic) && styles.static,
      ]}
    >
      {headerDisclaimer}
      <Container
        sx={{
          backgroundColor: headerBgColor || "",
          backgroundImage: `url("${headerBgImage}")` || "",
          backgroundSize: "cover",
        }}
      >
        <Box
          display="grid"
          sx={{
            gridTemplateColumns: isEmbedded
              ? { xs: "1fr" }
              : { xs: "1fr auto 1fr", lg: "1fr 1fr" },
          }}
          alignContent="center"
          justifyContent="space-between"
          alignItems="center"
          height={isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP}
        >
          {!isEmbedded && (
            <Box
              sx={{
                gridColumnStart: { xs: 2, lg: 1 },
                mr: 2,
                ml: { xs: 2, lg: 0 },
                mt: 0.25,
              }}
            >
              <CustomLink href="/">
                <Box sx={styles.cursor}>
                  {isCustomClient || logo ? (
                    <Image
                      src={
                        logo ||
                        `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/stores/sneaker-heads/logo.png`
                      }
                      width={140}
                      height={52}
                      alt=""
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <LogoIcon sx={{ width: 111, height: 38 }} />
                  )}
                </Box>
              </CustomLink>
            </Box>
          )}

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              height: "100%",
              width: "100%",
            }}
          >
            <HeaderLinks isEmbedded={isEmbedded} />
          </Box>

          <Box sx={{ display: { xs: "flex", lg: "none" }, justifySelf: "end" }}>
            <IconButton
              style={{
                color: headerMenuButtonColor || theme.palette.primary.main,
              }}
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "flex", lg: "none" } }}>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            sx={{
              "&.MuiDrawer-modal": styles.drawerModal,
              "& .MuiPaper-root": styles.drawerPaper,
            }}
          >
            <IconButton
              aria-label="close drawer"
              onClick={handleDrawerToggle}
              sx={styles.closeIcon}
            >
              <Close />
            </IconButton>
            <Box px={2} py={4}>
              <HeaderLinks isEmbedded={isEmbedded} />
            </Box>
          </Drawer>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
