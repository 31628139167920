import { FC } from "react";

import isArray from "lodash/isArray";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TwitterIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";

import TwitchIcon from "components/Icons/Twitch";
import DiscordIcon from "components/Icons/Discord";
import TiktokIcon from "components/Icons/Tiktok";
import OpenseaIcon from "components/Icons/Opensea";
import MediumIcon from "components/Icons/Medium";
import SocialLink from "components/SocialLink";

import { SOCIALS } from "enums/socials";

const socials = [
  { icon: <TwitterIcon />, urlProp: "twitterUrl", name: SOCIALS.TWITTER },
  { icon: <FacebookIcon />, urlProp: "facebookUrl", name: SOCIALS.FACEBOOK },
  { icon: <InstagramIcon />, urlProp: "instagramUrl", name: SOCIALS.INSTAGRAM },
  { icon: <YoutubeIcon />, urlProp: "youtubeUrl", name: SOCIALS.YOUTUBE },
  { icon: <TwitchIcon />, urlProp: "twitchUrl", name: SOCIALS.TWITCH },
  { icon: <LanguageIcon />, urlProp: "websiteUrl", name: SOCIALS.WEBSITE },
  { icon: <LinkedInIcon />, urlProp: "linkedinUrl", name: SOCIALS.LINKEDIN },
  { icon: <TelegramIcon />, urlProp: "telegramUrl", name: SOCIALS.TELEGRAM },
  { icon: <DiscordIcon />, urlProp: "discordUrl", name: SOCIALS.DISCORD },
  { icon: <TiktokIcon />, urlProp: "tiktokUrl", name: SOCIALS.TIKTOK },
  { icon: <OpenseaIcon />, urlProp: "openseaUrl", name: SOCIALS.OPENSEA },
  { icon: <MediumIcon />, urlProp: "mediumUrl", name: SOCIALS.MEDIUM },
];

interface SocialGroupObj {
  twitterUrl?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  youtubeUrl?: string | null;
  twitchUrl?: string | null;
  websiteUrl?: string | null;
  linkedinUrl?: string | null;
  telegramUrl?: string | null;
  discordUrl?: string | null;
  tiktokUrl?: string | null;
  openseaUrl?: string | null;
  mediumUrl?: string | null;
}

interface SocialGroupProps {
  items:
    | {
        name: SOCIALS;
        link: string | null;
      }[]
    | SocialGroupObj;
}

const SocialGroup: FC<SocialGroupProps> = ({ items }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const filteredItems: {
    name: SOCIALS;
    icon: JSX.Element;
    link: string;
  }[] = [];

  if (isArray(items)) {
    items.forEach((item) => {
      if (!item.link) return;
      const social = socials.find((social) => social.name === item.name);
      if (!social) return;

      filteredItems.push({
        name: item.name,
        icon: social.icon,
        link: item.link,
      });
    });
  } else {
    for (const [key, value] of Object.entries(items)) {
      if (!value) continue;
      const social = socials.find((social) => social.urlProp === key);
      if (!social) continue;

      filteredItems.push({
        name: social.name,
        icon: social.icon,
        link: value,
      });
    }
  }

  if (filteredItems.length < 1) return null;

  return (
    <Box
      justifyContent="center"
      display="flex"
      sx={{
        mt: { xs: 0, md: 4 },
      }}
    >
      {filteredItems.map((item, key) => {
        const isLast = filteredItems.length - 1 === key;

        return (
          <SocialLink
            key={`social-${item.name}`}
            href={item.link || ""}
            noMarginRight={isLast && !mobile}
          >
            {item.icon}
          </SocialLink>
        );
      })}
    </Box>
  );
};

export default SocialGroup;
