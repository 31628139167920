import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TwitchIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0,512) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1112 4317 l-382 -382 0 -1378 0 -1377 455 0 455 0 0 -382 0 -383
383 383 382 382 305 0 305 0 688 688 687 687 0 1073 0 1072 -1448 0 -1447 0
-383 -383z m2968 -769 l0 -833 -302 -302 -303 -303 -305 0 -305 0 -262 -262
-263 -263 0 263 0 262 -345 0 -345 0 0 1135 0 1135 1215 0 1215 0 0 -832z"
      />
      <path d="M2480 3400 l0 -460 155 0 155 0 0 460 0 460 -155 0 -155 0 0 -460z" />
      <path d="M3320 3400 l0 -460 155 0 155 0 0 460 0 460 -155 0 -155 0 0 -460z" />
    </g>
  </SvgIcon>
);

export default TwitchIcon;
