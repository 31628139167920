import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { selectChainId } from "redux/slices/metamask";

import { CHAIN_IDS } from "enums/chainIds";

import { APP_ROUTES } from "config/routes";

import { isPolygonNetwork } from "utils/blockchain";

export const useMarketplaceUrl = (givenChainId?: CHAIN_IDS | null): string => {
  const chainId = useSelector(selectChainId);
  const [marketplaceUrl, setMarketplaceUrl] = useState(APP_ROUTES.MARKETPLACE);

  useEffect(() => {
    isPolygonNetwork(givenChainId ?? chainId) &&
      setMarketplaceUrl(`${APP_ROUTES.MARKETPLACE}/polygon`);
  }, [chainId, givenChainId]);

  return marketplaceUrl;
};
