export enum SOCIALS {
  TWITTER = "X",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  TWITCH = "TWITCH",
  WEBSITE = "WEBSITE",
  LINKEDIN = "LINKEDIN",
  TELEGRAM = "TELEGRAM",
  DISCORD = "DISCORD",
  TIKTOK = "TIKTOK",
  OPENSEA = "OPENSEA",
  MEDIUM = "MEDIUM",
}
